<!-- Teonet crypt demo page -->
<template>
  <div>
    <h1>Teonet crypt package</h1>
    <form>
      <div class="form-group">
        <!-- Key: -->
        <label for="key">Key</label>
        <input
          type="key"
          class="form-control"
          id="key"
          v-model="key"
          placeholder="Add key"
        />
        <br />

        <!-- Input text: -->
        <label for="inputText">Input text</label>
        <textarea
          class="form-control"
          id="inputText"
          rows="3"
          v-model="input"
          placeholder="Add input text"
        ></textarea>
        <br />

        <!-- Encrypted text: -->
        <label for="encryptedText">Encrypted text</label>
        <textarea
          disabled
          class="form-control"
          id="encryptedText"
          rows="3"
          v-model="encrypted"
        ></textarea>
        <br />

        <!-- Decrypted text: -->
        <label for="decryptedText">Decrypted text</label>
        <textarea
          disabled
          class="form-control"
          id="decryptedText"
          rows="3"
          v-model="decrypted"
        ></textarea>
        <br />

        <!-- Encrypt button: -->
        <button @click="encrypt" type="button" class="btn btn-primary">
          Encrypt</button
        ><br />
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "MonitorApps",
  data() {
    return {
      key: "key1234567890123",
      input: "my\nsecret\ntext",
      encrypted: "",
      decrypted: "",
    };
  },
  methods: {
    encrypt() {
      let key = window.teoHashKey(this.key);
      console.debug("key:", key);
      this.encrypted = window.teoEncrypt(key, this.input);
      this.decrypted = window.teoDecrypt(key, this.encrypted);
    },
  },
};
</script>
