<template>
  <div>
    <img alt="Vue logo" src="./assets/logo.png" />
    <TeoConnect />
    <!-- <HelloWorld msg="Welcome to Your Vue.js App" /> -->
    <div class="menu">
      <ul>
        <li>
          <a href="#/" class="btn btn-secondary btn-sm">Home</a>
        </li>
        <li>
          <a href="#/apps" class="btn btn-secondary btn-sm">Apps</a>
        </li>
        <li>
          <a href="#/crypt" class="btn btn-secondary btn-sm">Crypt</a>
        </li>
        <li>
          <a href="#/info" class="btn btn-secondary btn-sm">Info</a>
        </li>
      </ul>
      <hr>
    </div>
    <component :is="currentView" />
    <TeoFooter />
  </div>
</template>

<script>
import TeoConnect from "./components/TeoConnect.vue";
import TeoFooter from "./components/TeoFooter.vue";

import HelloWorld from "./components/HelloWorld.vue";
import InfoPage from "./components/InfoPage.vue";
import MonitorApps from "./components/teo/monitor/MonitorApps.vue";
import TeoCrypt from "./components/teo/crypt/CryptDemo.vue";

const routes = {
  "/": HelloWorld,
  "/apps": MonitorApps,
  "/crypt": TeoCrypt,
  "/info": InfoPage,
};

export default {
  name: "App",
  components: {
    TeoConnect,
    TeoFooter,
  },
  data() {
    return {
      currentPath: window.location.hash,
    };
  },
  computed: {
    currentView() {
      return routes[this.currentPath.slice(1) || "/"] || HelloWorld;
    },
  },
  mounted() {
    window.addEventListener("hashchange", () => {
      this.currentPath = window.location.hash;
    });
  },
};
</script>

<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px; */
}

/* For all application */
body a {
  color: #42b983;
}
body hr {
  color: gray;
}

/* For menu only */
div.menu ul {
  list-style-type: none;
  padding: 0;
}
div.menu ul li {
  display: inline-block;
  margin: 0 5px;
}
div.menu ul li:first-of-type {
  margin-left: 0;
}

</style>
