<!-- Teo connection with Online info -->
<template>
  <small class="text-muted">
    <div class="name">name: {{ name }}</div>
    <!-- <div class="address">address: {{ address }}</div> -->
    <div class="name">teoname: {{ teoname }}</div>
    <div class="uptime">uptime: {{ uptime }}</div>
    <div class="version">version: {{ version }}</div>
    <div class="clients">clients: {{ clients }}</div>
    <div v-if="online" class="online"><a href="./">online</a></div>
    <div v-if="!online" class="offline"><a href="./">offline</a></div>
    <button
      v-if="disconnected"
      type="button"
      class="btn btn-warning"
      @click="reconnect()"
    >
      Reconnect
    </button>
    <hr>
  </small>
</template>

<script>
import { uuid } from "vue-uuid";

// Defalut teoweb commands
const cmdClients = "clients";

// This application teoweb commands
const cmdName = "name";
const cmdUptime = "uptime";
const cmdVersion = "version";

export default {
  name: "TeoWeb",

  data() {
    return {
      name: "Loading ...",
      teoname: "Loading ...",
      uptime: "0",
      version: "...",
      clients: "0",
      players: "0",
      queue: "0",
      online: false,
      disconnected: false,
    };
  },

  mounted: function () {
    this.connect();
  },

  unmounted: function () {
    this.teoweb.delReader(this.reader);
  },

  methods: {
    /** Connect to Teonet server. */
    connect() {
      let that = this;

      // Get this browser name from local storage
      this.teoname = uuid.v1();
      console.debug("teoname:", this.teoname);

      // Get name and version from local storage
      if (localStorage.name) this.name = localStorage.name;
      if (localStorage.version) this.version = localStorage.version;

      // Connect to Teonet proxy WebRTC server
      const serverName = "server-1"; // TODO: Get WenRTC server name from some parameter
      const port = "8083"; // TODO: Get ws server port from some parameter
      let scheme;
      let host;
      const hostname = "signal.teonet.dev" // window.location.hostname;
      if (hostname == "localhost" || hostname == "127.0.0.1") {
        scheme = "ws";
        host = hostname + ":" + port;
      } else {
        scheme = "wss";
        // host = "signal." + hostname;
        // host = "gt7-2.myteo.net"
        // host = hostname;
        host = "signal.teonet.dev";
      }
      this.teoweb.connect(
        scheme + "://" + host + "/signal",
        this.teoname,
        serverName
      );

      // Listen for WebRTC on open event
      this.teoweb.onOpen(function () {
        console.debug("onOpen");
        that.online = true;
        that.disconnected = false;
        that.teoweb.sendCmd(cmdName);
        that.teoweb.sendCmd(cmdUptime);
        that.teoweb.sendCmd(cmdVersion);
        that.teoweb.sendCmd(cmdClients);
        that.teoweb.subscribeCmd(cmdClients);
      });

      // Listen for WebRTC on close event
      this.teoweb.onClose(function (b) {
        console.debug("onClose");
        that.online = false;
        if (b === true) {
          that.disconnected = true;
        }
      });

      // Listen for WebRTC data from server
      this.teoweb.addReader(function (gw, data) {
        switch (gw.command) {
          case cmdClients:
            that.clients = data;
            break;
          case cmdName:
            that.name = data;
            localStorage.name = that.name;
            break;
          case cmdVersion:
            that.version = data;
            localStorage.version = that.name;
            break;
          case cmdUptime:
            that.uptime = data;
            break;
        }
      });
    },

    /** Reconnect to Teonet server */
    reconnect() {
      location.reload();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.online, .online a {
  color: #42b983  !important;
}
.offline, .offline a {
  color: red !important;
}
.online a, .offline a {
  text-decoration: none;
}
</style>
