import { createApp } from 'vue'
import App from './App.vue'

import teoweb from 'teoweb' // to use teonet webrtc connection
import 'bootstrap/dist/css/bootstrap.css' // to use bootstrap CSS
import 'bootstrap/dist/js/bootstrap.js' // to use bootstrap JS
import './bootstrap_theme_tolge.js' // to use bootstrap color theme tolge

const app = createApp(App)
app.config.globalProperties.teoweb = teoweb();
app.mount('#app')