<template>
  <div>
    <h1>Info page</h1>
    <TeoInfo />
  </div>
</template>

<script>
import TeoInfo from "./TeoInfo.vue";

export default {
  name: "App",
  components: {
    TeoInfo,
  },
};
</script>
