<!-- Teonet description in copyright. Set it in footer. -->
<template>
  <div>
    <hr class="norm" />
    <div class="copyr text-center text-secondary">Teonet © 2024</div>
  </div>
</template>

<style>
hr.norm {
  margin-top: 20px;
}
.copyr {
  margin-bottom: 1rem;
}
</style>
