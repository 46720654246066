<!-- Teonet monitor application. -->
<template>
  <div>
    <!-- Application name and Online status -->
    <div class="name row">
      <!-- Application name and Online status and Delete button -->
      <div class="col">
        <div class="row">
          <div class="col">
            <!-- Application name -->
            <h5>{{ app.AppShort }}</h5>

            <!-- Online status and Number of peers connected -->
            <div class="connected">
              <!-- Online status -->
              <small v-if="app.Online" class="text-secondary">
                <span class="text-success">online</span>, peers connected:
                {{ app.Peers }}
              </small>
              <!-- Offline status -->
              <small
                v-if="!app.Online && app.MayOffline != 1"
                class="text-secondary"
              >
                <span class="text-danger">offline</span>
              </small>
              <!-- Offline status and May be offline -->
              <small
                v-if="!app.Online && app.MayOffline == 1"
                class="text-secondary"
              >
                <span class="text-secondary">offline</span>
              </small>
            </div>
          </div>

          <!-- Delete offline application from monitor button -->
          <div class="col" v-if="!app.Online">
            <button
              class="btn btn-outline-secondary btn-sm"
              title="Delete application from monitor"
            >
              Del
            </button>
          </div>
        </div>
      </div>

      <!-- Application start date -->
      <small class="col-3 text-secondary float text-end">
        {{ formatDate(app.AppStartTime) }}
      </small>
    </div>

    <!-- Application description -->
    <div>{{ app.AppName }}</div>

    <!-- Application parameters -->
    <small class="text-secondary">
      <!-- Application address -->
      <div class="text-muted">{{ app.Address }}</div>

      <!-- Copy address to clipboard button -->
      <!-- <ion-button title="Copy address to clipboard">
        <ion-icon></ion-icon>
      </ion-button> -->

      <!-- Application version -->
      <div>
        version: {{ app.AppVersion }}, based on Teonet v5 ver
        {{ app.TeoVersion }}
      </div>

      <!-- Application host -->
      <div v-if="app.Host">host: {{ app.Host }}</div>
    </small>
  </div>
</template>

<script>
export default {
  name: "MonitorApp",

  data() {
    return {
      app_example: {
        name: "teoapi",
        date: "2023-02-07",
        desc: "Teonet api server sample application",
        addr: "9BvICGaBLaeAE6Wor7YJ6qouGlVc3FYQKnW",
        ver: "0.6.0",
        ver_teo: "0.6.0",
        host: "gt7_2",
        online: true,
        num_peers: 2,
      },
    };
  },

  props: {
    app: {},
  },

  methods: {
    formatDate(date) {
      return new Date(date).toLocaleString();
    },
  },
};
</script>

<style>
.name {
  margin-bottom: 0.15rem;
}
.connected {
  margin-top: -0.8rem;
}
</style>
